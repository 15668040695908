import { constraints } from "./solver/constraintDefinitions";

export class SelectionWidget {
    static selectedItems = [];
    static selectedPoints = [];
    constructor() {
        // create a div that floats in the top right corner of the screen
        this.widget = document.createElement("div");
        this.widget.style.position = "absolute";
        this.widget.style.top = "35px";
        this.widget.style.right = "0px";
        this.widget.style.minWidth = "0px";
        this.widget.style.maxWidth = "350px";
        this.widget.style.color = "white";
        this.widget.style.padding = "10px";
        this.widget.style.borderRadius = "5px";
        this.widget.style.boxShadow = "0 0 10px 0 rgba(0, 0, 0, 0.2)";
        this.widget.style.zIndex = "1000";
        this.widget.id = "selectionWidget";
        this.widget.style.overflowWrap = "break-word";
        document.body.appendChild(this.widget);
    }

    async updateWidget() {

        // remove all children from the widget and log each child element removed.
        this.widget.innerHTML = await "";
        if (SelectionWidget.selectedItems.length === 0) return;

        console.log(this.widget.children);

        const leftDiv = document.getElementById("leftDiv");
        const countDiv = document.createElement("div");
        countDiv.innerHTML = `Selected: ${SelectionWidget.selectedItems.length}`;
        await this.widget.appendChild(countDiv);

        let geometryDivFlag = false;
        let constraintDivFlag = false;
        await SelectionWidget.selectedItems.forEach(await async function (item) {
            try {
                const div = leftDiv.querySelector(`[data-id="${item.id}"][data-type="${item.type}"]`);
                //if (item.type === "geometry") geometryDivFlag = true;
                //if (item.type === "constraint") constraintDivFlag = true;
                console.log(`${item.type} constraintDivFlag: ${constraintDivFlag} geometryDivFlag: ${geometryDivFlag}`)
                const divCopy = div.cloneNode(true);
                if (div) this.widget.appendChild(divCopy);
            } catch (e) { }

        });

        // make a new div that will display the list of selected points

        const points = this.getPoints();
        const pointsActionsDiv = document.createElement("div");
        pointsActionsDiv.id = "pointsActionsDiv";
        if (points.length > 0) {
            const pointListDiv = document.createElement("div");
            pointListDiv.innerHTML = `Selected Points: ${points.join(", ")}`;
            this.widget.appendChild(pointListDiv);


            pointsActionsDiv.classList.add("selectWidgetToolbar");
            //this.widget.appendChild(pointsActionsDiv);
        }



        const constraintFunctions = constraints.constraintFunctions;


        for (const [key, func] of Object.entries(constraintFunctions)) {
            //console.log(`Key: ${key}, Function:`, func, `Metadata:`, func.someHelpfulMetaData);

            // check if the points.length is equal to the number of points required for the constraint
            if (func.hints.pointsRequired === points.length) {
                const button = document.createElement("button");
                button.classList.add("toolButton");
                button.innerHTML = key;
                // add a tooltip
                button.title = func.hints.commandTooltip;
                button.onclick = () => {
                    CAD.sketch.createConstraint(key, this.getItems());
                };
                pointsActionsDiv.appendChild(button);
            }

        }



        const geometriesActionsDiv = document.createElement("div");
        geometriesActionsDiv.id = "geometriesActionsDiv";
        geometriesActionsDiv.classList.add("selectWidgetToolbar");
        //this.widget.appendChild(geometriesActionsDiv);


        const button = document.createElement("button");
        button.classList.add("toolButton");
        button.innerHTML = "◐";
        // add a tooltip
        button.title = "Toggle construction geometry";
        button.onclick = CAD.sketch.toggleConstruction;
        geometriesActionsDiv.appendChild(button);




        const constraintsActionsDiv = document.createElement("div");
        constraintsActionsDiv.innerHTML = "Constraints";
        constraintsActionsDiv.id = "constraintsActionsDiv";
        constraintsActionsDiv.classList.add("selectWidgetToolbar");



        //make a button to invert an angle constraint
        const invertAngleButton = document.createElement("button");
        invertAngleButton.classList.add("toolButton");
        invertAngleButton.innerHTML = "⤵";
        invertAngleButton.title = "Reposition angle constraint to next possible position.";
        invertAngleButton.onclick = () => {
            CAD.sketch.invertAngleConstraint();
        };
        constraintsActionsDiv.appendChild(invertAngleButton);




        // check each of the actions divs to see if they are empty
        // if they are empty, remove them
        if (pointsActionsDiv.children.length > 0) this.widget.appendChild(pointsActionsDiv);
        if (geometryDivFlag) this.widget.appendChild(geometriesActionsDiv);
        if (constraintDivFlag) this.widget.appendChild(constraintsActionsDiv);


    }

    addItem(item) {
        SelectionWidget.selectedItems.push(item);
        this.updateWidget();
    }

    removeItem(item) {
        SelectionWidget.selectedItems = SelectionWidget.selectedItems.filter((i) => i !== item);
        this.updateWidget();
    }

    clearItems() {
        SelectionWidget.selectedItems = [];
        this.updateWidget();
    }

    replaceItems(items) {
        SelectionWidget.selectedItems = items;
        this.updateWidget();
    }

    getItems(type = null) {
        if (type) {
            return SelectionWidget.selectedItems.filter((item) => item.type === type);
        } else {
            return SelectionWidget.selectedItems;
        }
    }

    getPoints() {
        let geometryType = null;
        SelectionWidget.selectedPoints = [];


        for (const item of SelectionWidget.selectedItems) {
            if (item.type === "point") {
                SelectionWidget.selectedPoints.push(window.sketchObject.points.find((p) => p.id === parseInt(item.id)));
            }
            if (item.type === "geometry") {
                let geometry = window.sketchObject.geometries.find((g) => g.id === parseInt(item.id));
                for (const pId of geometry.points) {
                    SelectionWidget.selectedPoints.push(window.sketchObject.points.find((p) => p.id === pId));
                }
                if (geometry.type === "arc") SelectionWidget.selectedPoints.pop();

                geometryType = geometry.type;
            }
        }
        SelectionWidget.selectedPoints = SelectionWidget.selectedPoints.map((p) => parseInt(p.id));
        return SelectionWidget.selectedPoints;
    }
}