"use strict";
import { settings } from "./settings.js";
import { sketch } from "./solver/sketchObject.js";
import { leftSidebar } from "./leftSidebar.js";
import { renderer } from "./viewer/renderer.js";
import { toolbarWidget } from "./toolbar.js";
import { SelectionWidget } from "./selectionWidget.js";
import { statusBarMessage } from "./statusBar.js";




import "./style.css";





export const CAD = {
  settings,
  sketch,
  leftSidebar,
  renderer,
  toolbarWidget,
  appState: {
    mode: "",
    type: "",
    SelectionWidget: new SelectionWidget(),
    requiredSelections: 0,
    resetState: function () {
      this.mode = "";
      this.type = "";
      this.SelectionWidget.clearItems();
    },
    setMode: function (mode) {
      this.mode = mode;
      statusBarMessage(`${this.mode} ${this.type}`);
    },
    setType: function (type) {
      this.type = type;
      statusBarMessage(`${this.mode} ${this.type}`);
    },
  }
};
window.CAD = CAD;
generateHTML();





function generateHTML() {
  // Create and set up the body elements
  const body = document.body;
}






async function postLoadDoStuff() {
  await CAD.sketch.setup(CAD);
  await CAD.renderer.setup(CAD);
  await CAD.toolbarWidget.setup(CAD);

  await CAD.sketch.fileIO.setup(CAD);
  await CAD.sketch.fileIO.loadSketch();

  await CAD.sketch.solveSketch(sketch.sketchObject, 10);

  await CAD.renderer.updateCanvas();
}
postLoadDoStuff();
