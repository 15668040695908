"use strict";


export function notifyUser(message, type = "info", timeout = 5000) {
  ///create notification popup and add to the page. 
  /// remove the notification element after 2 seconds
  let notification = document.createElement("div");
  notification.style.position = "absolute";
  notification.style.bottom = "30px";
  notification.style.right = "5px";
  notification.style.width = "350px";
  notification.style.height = "50px";
  notification.style.padding = "0px";
  notification.style.margin = "0px 0px 15px 0px";
  notification.style.borderRadius = "25px";
  notification.style.backgroundColor = "white";


  let notificationIcon = document.createElement("div");
  notificationIcon.style.textAlign = "center";
  notificationIcon.style.lineHeight = "60px";
  notificationIcon.style.width = "60px";
  notificationIcon.style.height = "60px";
  notificationIcon.style.margin = "-5px";
  notificationIcon.style.backgroundColor = "red";
  notificationIcon.style.borderRadius = "50%";
  notificationIcon.style.display = "inline-block";
  notificationIcon.style.float = "left";
  notificationIcon.style.fontSize = "35px";
  notificationIcon.style.fontWeight = "bold";
  notificationIcon.style.color = "white";
  notification.appendChild(notificationIcon);


  let notificationText = document.createElement("div");
  notificationText.style.width = "calc(100% - 70px)";
  notificationText.style.height = "100%";
  notificationText.style.display = "inline-block";
  notificationText.style.float = "left";
  notificationText.style.padding = "5px";
  notificationText.style.whiteSpace = "pre";
  notificationText.innerHTML = message;
  notification.appendChild(notificationText);

  if (type === "info") {
    notificationIcon.innerHTML = "i";
    notificationIcon.style.backgroundColor = "blue";
  }
  if (type === "warning") {
    notificationIcon.innerHTML = "!";
    notificationIcon.style.backgroundColor = "orange";
  }
  if (type === "error") {
    notificationIcon.innerHTML = "X";
    notificationIcon.style.backgroundColor = "red";
  }

  document.body.appendChild(notification);
  setTimeout(() => {
    document.body.removeChild(notification);
  }, timeout);
}
