"use strict";
import "./toolbar.css";

let tools = [];
let CAD;

const toolbar = document.createElement("div");

async function setup(CAD_) {
  CAD = CAD_;

  tools = [
    {
      label: "💾",
      iconURL: "",
      function: CAD.sketch.fileIO.saveSketch,
      toolTip: "Save sketch",
    },
    {
      label: "📂",
      iconURL: "",
      function: CAD.sketch.fileIO.loadSketch,
      toolTip: "Load sketch",
    },
    "|",
    {
      /// unicode icon to represent export command
      label: "⬇",
      iconURL: "",
      function: CAD.sketch.export_occt,
      toolTip: "Export OCCT",
    },
    "|",
    {
      label: "⚙",
      iconURL: "",
      function: CAD.settings.showSettingsDialog,
      toolTip: "Settings",
    },
    {
      label: "🔍",
      iconURL: "",
      function: CAD.renderer.zoomFitAll,
      toolTip: "Zoom fit all",
    },
    "|",
    {
      label: "○",
      iconURL: "",
      function: CAD.renderer.createPointOnClick,
      toolTip: "Create point",
    },
    {
      label: "/",
      iconURL: "",
      function: CAD.sketch.geometryCreateLine,
      toolTip: "Create line",
    },
    {
      label: "◯",
      iconURL: "",
      function: CAD.sketch.geometryCreateCircle,
      toolTip: "Create circle",
    },

    {
      label: "◠",
      iconURL: "",
      function: CAD.sketch.geometryCreateArc,
      toolTip: "Create arc",
    },
    "|",
    {
      label: "◐",
      iconURL: "",
      function: CAD.sketch.toggleConstruction,
      toolTip: "Toggle construction geometry",
    },
    // "|",
    // {
    //   label: "━",
    //   iconURL: "",
    //   function: CAD.sketch.applyHorizontalConstraint,
    //   toolTip: "Apply horizontal constraint",
    // },
    // {
    //   label: "│",
    //   iconURL: "",
    //   function: CAD.sketch.applyVerticalConstraint,
    //   toolTip: "Apply vertical constraint",
    // },
    // {
    //   label: "⏚",
    //   iconURL: "",
    //   function: CAD.sketch.applyFixedPointConstraint,
    //   toolTip: "Apply fixed point constraint",
    // },
    // {
    //   label: "⟺",
    //   iconURL: "",
    //   function: CAD.sketch.applyDistanceConstraint,
    //   toolTip: "Apply distance constraint",
    // },
    // {
    //   label: "⇌",
    //   iconURL: "",
    //   function: CAD.sketch.applyEqualLengthConstraint,
    //   toolTip: "Apply equal length constraint",
    // },
    // {
    //   label: "≡",
    //   iconURL: "",
    //   function: CAD.sketch.applyCoincidenceConstraint,
    //   toolTip: "Apply coincidence constraint",
    // },
    // {
    //   label: "∥",
    //   iconURL: "",
    //   function: CAD.sketch.applyParallelConstraint,
    //   toolTip: "Apply parallel constraint",
    // },
    // {
    //   label: "⟂",
    //   iconURL: "",
    //   function: CAD.sketch.applyPerpendicularConstraint,
    //   toolTip: "Apply perpendicular constraint",
    // },
    // {
    //   label: "∠",
    //   iconURL: "",
    //   function: CAD.sketch.applyAngleConstraint,
    //   toolTip: "Apply angle constraint",
    // },
    // {
    //   label: "⏛",
    //   iconURL: "",
    //   function: CAD.sketch.applyPointOnLineConstraint,
    //   toolTip: "Apply point on line constraint",
    // },
    // {
    //   label: "⋯",
    //   iconURL: "",
    //   function: CAD.sketch.applyMidpointConstraint,
    //   toolTip: "Apply colinear constraint",
    // },
    "|",
    {
      label: "S",
      iconURL: "",
      function: CAD.sketch.simplifyCoincidentConstraints,
      toolTip: "simplify coincident constraints",
    },
    "|",
    {
      label: "2d",
      iconURL: "",
      function: CAD.renderer.toggleShowSVG,
      toolTip: "Show/Hide 2D sketch",
    },
    "|",
  ];






  await createToolbar();
  tools.forEach((element) => {
    if (element !== "|") addNewToolbarButton(element);

    // Add a separator
    else {
      let separator = document.createElement("div");
      separator.style.height = "100%";
      separator.style.width = "15px";
      toolbar.appendChild(separator);
    }
  });


};

let fileNameField = document.createElement("input");
fileNameField.id = "fileName";
fileNameField.type = "text";
fileNameField.placeholder = "Enter file name";
fileNameField.style.marginRight = "10px"; // Add some spacing between buttons
fileNameField.style.height = "100%"; // Adjust as needed
fileNameField.style.width = "100px"; // Adjust as needed



// Create and set up the toolbar
async function createToolbar() {

  toolbar.id = "toolbar";
  toolbar.classList.add("toolbar");

  document.body.appendChild(toolbar);


  fileNameField.value = "mySketch";
  await toolbar.appendChild(fileNameField);
}

// Add a new button to the toolbar
function addNewToolbarButton({ label, iconURL, function: callback, toolTip }) {
  //console.log(label, callback);
  if (!toolbar) {
    console.error(
      "Toolbar not found. Ensure you have called createToolbar() first."
    );
    return;
  }

  let button = document.createElement("button");
  if (iconURL) {
    let icon = document.createElement("img");
    icon.src = iconURL;
    icon.alt = label + " icon";
    icon.style.height = "20px"; // Adjust as needed
    icon.style.marginRight = "5px"; // Space between icon and label
    button.appendChild(icon);
  }
  button.appendChild(document.createTextNode(label));
  // add a css class to the button
  button.classList.add("toolButton");
  button.title = toolTip;
  button.addEventListener("click", callback);

  toolbar.appendChild(button);
}


export const toolbarWidget = {
  setup,
  createToolbar,
  addNewToolbarButton,
  fileNameField,
}