"use strict";
let sketcherSettings = {
    geometry: {
        color: "#ffffff",
        thickness: 2
    },
    constraints: {
        color: "#80a6ff",
        thickness: 1,
        labelColor: "#80a6ff",
        fontSize: 16,
        error_color: "#ff0000"
    },
    points: {
        color: "#fffb94",
        size: 4
    },
    background: {
        color: "#000000"
    },
    selected: {
        color: "#00ff00"
    },
    solver: {
        default_Loops: 30,
        final_Loops: 100,
        animate_Speed: 5,
    },
    autoSave: {
        enabled_bool: true,
        interval: 15000,
    },
}




const storedSettings = JSON.parse(localStorage.getItem("sketcherSettings")) || {};
deepMerge(sketcherSettings, storedSettings);

document.body.style.backgroundColor = sketcherSettings.background.color;

const settingsDialog = document.createElement("dialog");


/// iterate over each key in the sketcherSettings object
/// settings 
/// and build a html settingsDialog to edit the settings
function settingsDialogSetup() {

    settingsDialog.id = "settingsDialog";
    //settingsDialog.style.position = "absolute";

    settingsDialog.style.maxWidth = "300px";
    settingsDialog.style.minWidth = "270px";
    settingsDialog.style.maxHeight = "90%";
    settingsDialog.style.padding = "0px";

    settingsDialog.style.borderRadius = "3px";
    settingsDialog.style.border = "1px solid white";
    settingsDialog.style.backgroundColor = "gray";
    settingsDialog.style.zIndex = "1000000";
    document.body.appendChild(settingsDialog);

    const titleDiv = document.createElement("div");
    titleDiv.style.width = "100%";
    titleDiv.style.height = "30px";
    titleDiv.style.margin = "0px";
    titleDiv.style.padding = "0px";
    titleDiv.style.lineHeight = "30px";


    titleDiv.style.textAlign = "center";
    titleDiv.style.borderBottom = "2px solid black";
    titleDiv.innerHTML = "Settings";
    settingsDialog.appendChild(titleDiv);

    const closeButton = document.createElement("button");
    closeButton.innerHTML = "🗙";
    closeButton.style.fontSize = "30px";
    closeButton.style.lineHeight = "30px";
    closeButton.style.position = "absolute";
    closeButton.style.top = "0px";
    closeButton.style.right = "0px";
    closeButton.style.width = "30px";
    closeButton.style.height = "30px";
    closeButton.style.borderRadius = "3px";
    closeButton.style.padding = "0px";
    closeButton.style.margin = "0px";
    closeButton.style.backgroundColor = "red";
    closeButton.style.borderBottom = "1px solid black";
    closeButton.style.borderLeft = "1px solid black";
    closeButton.style.borderTop = "none";
    closeButton.style.borderRight = "none";
    closeButton.style.zIndex = "1000001";
    closeButton.addEventListener("click", function () { settingsDialog.close(); });
    settingsDialog.appendChild(closeButton);

    for (const key in sketcherSettings) {
        // make a span element
        const span = document.createElement("span");
        span.innerHTML = key.toUpperCase();
        span.style.display = "block";
        span.style.margin = "0px";
        span.style.padding = "0px";
        span.style.width = "calc(100% - 5px)";
        //span.style.backgroundColor = "lightGray";
        span.style.textAlign = "left";
        span.style.height = "100%";
        span.style.border = "1px solid black";
        settingsDialog.appendChild(span);


        const div = document.createElement("div");
        div.style.width = "calc(100% - 55PX)";
        div.style.marginLeft = "40px";
        div.style.padding = "0px";
        div.style.paddingTop = "0px";
        div.style.paddingBottom = "0px";
        div.style.marginTop = "0px";
        div.style.marginBottom = "0px";
        div.style.backgroundColor = "lightGray";
        div.style.border = "1px solid black";
        settingsDialog.appendChild(div);





        for (const subKey in sketcherSettings[key]) {
            let input;
            if (subKey.toLowerCase().includes('color')) {
                input = document.createElement("input");
                input.type = "color";
                input.value = sketcherSettings[key][subKey];
            } else if (subKey.toLowerCase().includes('bool')) {
                input = document.createElement("input");
                input.type = "checkbox";
                input.checked = sketcherSettings[key][subKey];
            } else {
                input = document.createElement("input");
                input.type = "text";
                input.value = sketcherSettings[key][subKey];
            }

            input.style.display = "inline-block";
            input.style.backgroundColor = "white";
            input.style.borderBottom = "1px solid black";
            input.style.margin = "5px";
            input.style.width = "70px";
            input.style.textAlign = "left";
            div.appendChild(input);

            let label = document.createElement("label");
            label.innerHTML = (` ${subKey}`).toUpperCase();
            label.style.display = "inline-block";
            label.style.margin = "0px";
            label.style.width = "calc(100% - 90px)";
            label.style.padding = "0px";
            label.style.textAlign = "left";
            label.style.height = "100%";
            div.appendChild(label);

            input.addEventListener("change", function () {
                sketcherSettings[key][subKey] = parseInt(input.value, 10);
                if (input.type === "color") sketcherSettings[key][subKey] = input.value;
                if (input.type === "checkbox") sketcherSettings[key][subKey] = input.checked;
                document.body.style.backgroundColor = sketcherSettings.background.color;
                localStorage.setItem("sketcherSettings", JSON.stringify(sketcherSettings));
                updateCanvas();
            });
        }
    }
}




settingsDialogSetup();

function showSettingsDialog() {
    settingsDialog.showModal();
}




function deepMerge(target, source) {
    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                deepMerge(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
}

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}


export const settings = {
    sketcherSettings,
    showSettingsDialog
}