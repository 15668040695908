// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 30px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  filter: invert(1);
}

.selectWidgetToolbar {
  width: 100%;
  height: 30px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  z-index: 1000;
  display: flex;
  align-items: center;
  filter: invert(1);
}

.toolButton {
  margin: 3px;
  height: 30px;
  width: 30px;
  align-items: center;
  
}`, "",{"version":3,"sources":["webpack://./src/toolbar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,6BAA6B;EAC7B,aAAa;EACb,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,6BAA6B;EAC7B,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,mBAAmB;;AAErB","sourcesContent":[".toolbar {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 30px;\n  background-color: #f5f5f5;\n  border-bottom: 1px solid #ccc;\n  z-index: 1000;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  filter: invert(1);\n}\n\n.selectWidgetToolbar {\n  width: 100%;\n  height: 30px;\n  background-color: #f5f5f5;\n  border-bottom: 1px solid #ccc;\n  z-index: 1000;\n  display: flex;\n  align-items: center;\n  filter: invert(1);\n}\n\n.toolButton {\n  margin: 3px;\n  height: 30px;\n  width: 30px;\n  align-items: center;\n  \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
