"use strict";

import { fileIO } from "../fileIO.js";
import { undoSystem } from "../undo.js";
import { constraints } from "./constraintDefinitions.js";
import { distance, calculateAngle, shuffle, roundToDecimals } from "./mathHelpersMod.js";
import { notifyUser } from "../notifications.js";
import { export_OCCT_draw, runDrawCommand } from "./export_OCCT_draw.js";
import { statusBarMessage } from "../statusBar.js";
import { workerCall } from "./workerInterface.js";

let CAD;
let SelectionWidget;
let renderer;


const constraintFunctions = constraints.constraintFunctions;
let fullSolve = 1000;
let default_Loops = 1000;

const tolerance = 1e-6;


function setup(CAD_) {
    CAD = CAD_;
    fullSolve = function () { return parseInt(CAD.settings.sketcherSettings.solver.final_Loops) + 0; }
    default_Loops = function () { return parseInt(CAD.settings.sketcherSettings.solver.default_Loops) + 0; };
    renderer = CAD.renderer;
    SelectionWidget = CAD.appState.SelectionWidget;
}

window.sketchObject = {
    points: [{ id: 0, x: 0, y: 0, fixed: true },],
    geometries: [],
    constraints: [{ id: 0, type: "⏚", points: [0] },],
}




async function removePointById(id) {
    await solvePause();
    // Remove the point
    id = parseInt(id);
    if (id === 0) {
        await solveResume();
        return;
    }

    console.log("removing point", id);
    window.sketchObject.points = window.sketchObject.points.filter(point => point.id !== id);

    // Remove geometries that reference the point
    // geometries now have a points array that contains the IDs of the points
    window.sketchObject.geometries = window.sketchObject.geometries.filter(geometry => {
        return !geometry.points.includes(id);
    });

    // Remove constraints that reference the point
    window.sketchObject.constraints = window.sketchObject.constraints.filter(constraint => {
        return !constraint.points.includes(id);
    });
    await solveResume();
}

async function removeGeometryById(id) {
    await solvePause();
    // Remove the geometry
    id = parseInt(id);
    if (id === 0) {
        await solveResume();
        return;
    }

    window.sketchObject.geometries = window.sketchObject.geometries.filter(geometry => parseInt(geometry.id) !== id);

    // Remove constraints that reference the geometry (if any future constraints depend on specific geometries)
    window.sketchObject.constraints = window.sketchObject.constraints.filter(constraint => {
        // Assuming a constraint might have a 'geometryId' property that references a geometry
        return constraint.geometryId !== id;
    });
    await solvePause();
}

async function removeConstraintById(id) {
    await solvePause();
    id = parseInt(id);
    window.sketchObject.constraints = window.sketchObject.constraints.filter(constraint => parseInt(constraint.id) !== id);
    await solveResume();
}






async function toggleConstruction() {
    await solvePause();
    if (SelectionWidget.getItems().length === 0) return;
    SelectionWidget.getItems().forEach((item) => {
        if (item.type === "geometry") {
            let geometry = window.sketchObject.geometries.find((g) => g.id === parseInt(item.id));
            // check if construction property exists
            if (geometry.construction === undefined) geometry.construction = false;
            geometry.construction = !geometry.construction;
        }
    });

    renderer.updateCanvas(false);
    await solveResume();
}


async function invertAngleConstraint() {
    await solvePause();
    if (SelectionWidget.getItems().length === 0) return;
    SelectionWidget.getItems().forEach((item) => {
        if (item.type === "constraint") {
            let constraint = window.sketchObject.constraints.find((c) => c.id === parseInt(item.id));
            if (constraint.type === "∠") {
                constraint.value = null;
                constraint.points = constraint.points = [constraint.points[3], constraint.points[2], constraint.points[0], constraint.points[1],];
            }
        }
    });

    await renderer.updateCanvas(false);
    await solveResume();
    await renderer.updateCanvas(false);
}



function geometryCreateLine() {
    CAD.appState.mode = "createGeometry";
    CAD.appState.type = "line";
    CAD.appState.requiredSelections = 2;
    createGeometry("line");
}

function geometryCreateCircle() {
    CAD.appState.mode = "createGeometry";
    CAD.appState.type = "circle";
    CAD.appState.requiredSelections = 2;
    createGeometry("circle");
}

function geometryCreateArc() {
    CAD.appState.mode = "createGeometry";
    CAD.appState.type = "arc";
    CAD.appState.requiredSelections = 3;
    createGeometry("arc");
}



async function createGeometry(type, points = []) {
    await solvePause();
    await waitForSolveLoopToBeComplete();
    // If no points are provided, use the selected points
    if (points.length === 0 && SelectionWidget.getItems().length > 0) {
        points = [];
        SelectionWidget.getItems().forEach((item) => {
            if (item.type === "point") points.push(window.sketchObject.points.find((p) => p.id === parseInt(item.id)));
        });
    }

    if (points.length !== CAD.appState.requiredSelections) {
        //console.log("wrong number of points to make constraint");
        await solveResume();
        return false;
    }



    let pointIds;
    if (typeof points[0] === 'object') {
        pointIds = points.map((p) => p.id)
    } else {
        pointIds = points;
    };
    //console.log(typeof points[0], pointIds);

    /// Array of IDs of the points
    if (pointIds.length === 0) return false;


    let maxId = Math.max(...window.sketchObject.geometries.map((geo) => geo.id), 0) + 1;
    let newGeometry = {
        id: maxId,
        type: type,
        points: pointIds,
        construction: false,
    };

    undoSystem.saveUndoState();
    console.log("here is the new geometry", newGeometry);
    window.sketchObject.geometries.push(newGeometry);
    await solveResume();
    await solveSketch("full");


    console.log(window.sketchObject)
    //renderer.updateCanvas();
    CAD.appState.mode = "";
    CAD.appState.type = "";
    CAD.appState.requiredSelections = 0;
    return true;
}






async function createConstraint(type, currentlySelected) {
    //console.log(currentlySelected);
    await solvePause();
    await waitForSolveLoopToBeComplete();
    let selectedPoints = [];

    let geometryType = null;

    for (const item of currentlySelected) {
        if (item.type === "point") {
            selectedPoints.push(window.sketchObject.points.find((p) => p.id === parseInt(item.id)));
        }
        if (item.type === "geometry") {
            let geometry = window.sketchObject.geometries.find((g) => g.id === parseInt(item.id));
            for (const pId of geometry.points) {
                selectedPoints.push(window.sketchObject.points.find((p) => p.id === pId));
            }
            if (geometry.type === "arc") selectedPoints.pop();

            geometryType = geometry.type;
        }
    }


    //console.log(selectedPoints);
    /// give me an array of all the point ids in the selectedPoints array
    let selectedPointIds = selectedPoints.map((p) => parseInt(p.id));


    if (selectedPoints.length === 0) return;

    let newConstraint = {
        id: 0,
        type: type,
        points: selectedPointIds,
        labelX: 0,
        labelY: 0,
        displayStyle: "",
        value: null,
        valueNeedsSetup: true,
    };

    //newConstraint.vakue = parseFloat(newConstraint.value.toFixed(4));

    if (selectedPoints.length === 1) {
        if (type === "⏚") return createAndPushNewConstraint(newConstraint);
    }
    if (selectedPoints.length === 2) {
        if (type === "━") return createAndPushNewConstraint(newConstraint);
        if (type === "│") return createAndPushNewConstraint(newConstraint);
        if (type === "≡") return createAndPushNewConstraint(newConstraint);

        if (type === "⟺") {
            //newConstraint.value = distance(selectedPoints[0], selectedPoints[1])
            //newConstraint.value = parseFloat(newConstraint.value.toFixed(4));
            if (geometryType === "arc" || geometryType === "circle") newConstraint.displayStyle = "radius";

            return createAndPushNewConstraint(newConstraint);
        }

    }
    if (selectedPoints.length === 3) {
        if (type === "⏛") return createAndPushNewConstraint(newConstraint);
        if (type === "⋯") {

            if (currentlySelected[0].type === "point") {
                newConstraint.points = selectedPointIds.reverse();
            }


            return createAndPushNewConstraint(newConstraint);
        }
    }
    if (selectedPoints.length === 4) {
        if (type === "⟂") {
            let line1AngleA = calculateAngle(selectedPoints[0], selectedPoints[1]);
            let line1AngleB = calculateAngle(selectedPoints[1], selectedPoints[0]);
            let line2Angle = calculateAngle(selectedPoints[2], selectedPoints[3]);

            // Normalize angles to [-180, 180)
            line1AngleA = (line1AngleA + 180) % 360 - 180;
            line1AngleB = (line1AngleB + 180) % 360 - 180;
            line2Angle = (line2Angle + 180) % 360 - 180;

            let differenceBetweenAnglesA = line1AngleA - line2Angle;
            let differenceBetweenAnglesB = line1AngleB - line2Angle;

            // Determine which angle is closer to the target angle
            // swap the points if the other angle is closer
            if (Math.abs(90 - differenceBetweenAnglesA) > Math.abs(90 - differenceBetweenAnglesB)) {
                [newConstraint.points[0], newConstraint.points[1]] = [newConstraint.points[1], newConstraint.points[0]];
            }

            return createAndPushNewConstraint(newConstraint);
        }
        if (type === "∥") {
            return createAndPushNewConstraint(newConstraint);
        }
        if (type === "∠") {
            /// calculate the current angle between the lines formed by the points
            /// and set the value of the constraint to the current angle

            let line1Angle = calculateAngle(selectedPoints[0], selectedPoints[1]);
            let line2Angle = calculateAngle(selectedPoints[2], selectedPoints[3]);

            // Normalize angles to [-180, 180)
            line1Angle = (line1Angle + 180) % 360 - 180;
            line2Angle = (line2Angle + 180) % 360 - 180;

            let differenceBetweenAngles = line1Angle - line2Angle;

            // Normalize difference to 
            differenceBetweenAngles = (differenceBetweenAngles + 360) % 360;

            newConstraint.value = differenceBetweenAngles
            return createAndPushNewConstraint(newConstraint);
        }
        if (type === "⇌") return createAndPushNewConstraint(newConstraint);

    }
    solveResume();
    notifyUser("Invalid selection for constraint type " + type + "\n" + "with " + selectedPoints.length + " points.", "warning");
}



async function createAndPushNewConstraint(constraint) {
    await solvePause();
    await waitForSolveLoopToBeComplete();
    let maxId = Math.max(...window.sketchObject.constraints.map((c) => c.id), 0) + 1;
    constraint.id = maxId;
    constraint.value = (constraint.value === null) ? null : parseFloat(constraint.value.toFixed(4));
    undoSystem.saveUndoState();
    window.sketchObject.constraints.push(constraint);
    //await solveSketch("full");

    await solveResume();
    notifyUser("Constraint added", "info");
}


function export_occt(){
   
    return export_OCCT_draw(sketchObject);
}




export const sketch = {
    sketchObject: window.sketchObject,
    undoSystem,
    setup,
    removePointById,
    removeGeometryById,
    removeConstraintById,
    toggleConstruction,
    geometryCreateLine,
    geometryCreateCircle,
    geometryCreateArc,
    createConstraint,
    createGeometry,
    invertAngleConstraint,
    createAndPushNewConstraint,
    solveSketch,
    runDrawCommand,
    fileIO,
    getPointById,
    simplifyCoincidentConstraints,
    solvePause,
    solveResume,
    waitForSolveLoopToBeComplete,
    export_occt
}















/// function that looks up a point by its id
function getPointById(id) {
    return window.sketchObject.points.find((p) => p.id === parseInt(id));
}





async function solveSketch(iterations) {
    window.tickerPausedStatusFlag = false;

    if (iterations === null) iterations = default_Loops();
    if (iterations === "full") iterations = fullSolve();

    try {
        window.sketchObject = await workerCall({
            functionName: "solveSketch",
            args: [window.sketchObject]
        });
    } catch (error) {
        window.tickerPausedStatusFlag = true;
    }

    window.tickerPausedStatusFlag = true;
}




function simplifyCoincidentConstraints() {
    // Step 1: Identify coincident constraints and group points
    let data = window.sketchObject;
    const coincidentGroups = {};
    const pointToGroup = {};

    data.constraints.forEach(constraint => {
        if (constraint.type === "≡") {
            const [p1, p2] = constraint.points;
            if (!coincidentGroups[p1]) coincidentGroups[p1] = new Set();
            if (!coincidentGroups[p2]) coincidentGroups[p2] = new Set();

            coincidentGroups[p1].add(p2);
            coincidentGroups[p2].add(p1);
        }
    });

    // Merge groups that share common points
    for (const [point, group] of Object.entries(coincidentGroups)) {
        for (const otherPoint of group) {
            if (coincidentGroups[otherPoint]) {
                for (const p of coincidentGroups[otherPoint]) {
                    group.add(p);
                    coincidentGroups[p] = group;
                }
            }
        }
    }

    // Map each point to its group's minimum ID
    for (const [point, group] of Object.entries(coincidentGroups)) {
        const minId = Math.min(...Array.from(group));
        console.log(minId);
        pointToGroup[point] = minId;
    }


    console.log(pointToGroup, coincidentGroups)
    // Step 2: Replace IDs in constraints and geometries
    data.constraints.forEach(constraint => {
        constraint.points = constraint.points.map(p => pointToGroup[p] || p);
        console.log(constraint.points);
    });

    data.geometries.forEach(geometry => {
        geometry.points = geometry.points.map(p => pointToGroup[p] || p);
    });

    discardUnusedPoints();

    // remove all coincident constraint that have the same point twice
    data.constraints = data.constraints.filter(c => !(c.type === "≡" && c.points[0] === c.points[1]));

    return data;
}


function discardUnusedPoints() {
    let data = window.sketchObject;
    const usedPoints = new Set();

    // Collect points that are used in constraints
    data.constraints.forEach(constraint => {
        constraint.points.forEach(pointId => {
            usedPoints.add(pointId);
        });
    });

    // Collect points that are used in geometries
    data.geometries.forEach(geometry => {
        geometry.points.forEach(pointId => {
            usedPoints.add(pointId);
        });
    });

    // Filter out points that are not used
    data.points = data.points.filter(point => usedPoints.has(point.id));

    return data;
}



async function solvePause() {
    window.tickerPaused = true;
    await waitForSolveLoopToBeComplete();

}

async function solveResume() {
    window.tickerPaused = false;
}



function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

// Async function that waits for a condition to become false
async function waitForSolveLoopToBeComplete() {
    while (window.tickerPausedStatusFlag === false) {
        // Wait for 100 milliseconds before checking the condition again
        //console.log("waiting for solve loop to be complete");
        await delay(100);
        console.log("solver is still running");
    }
    //console.log("solver no longer running")
    // Once the condition is false, the code below this line will execute
    //console.log('The condition is now false, continuing execution...');
    // Place the rest of your function's code here
}