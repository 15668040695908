"use strict";
import {
  roundToDecimals,
  offsetLine,
  getIntersectionPoint,
  calcPerpendicularDistanceLineToPoint,
  distance,
  averagePoint,
  calculateAngle,
  lineIntersection,
  findArcIntersections,
  describeArc
} from "../solver/mathHelpersMod.js";
import {
  svgSymbols,
} from "./svgSymbols.js";

import { statusBarMessage } from "../statusBar.js";



let CAD;


let sketcherSettings;
let leftSidebar;
let sketch;
let undoSystem;
let SelectionWidget;
let solveSketch;

async function setup(CAD_) {
  CAD = CAD_;
  sketch = CAD.sketch;
  sketcherSettings = CAD.settings.sketcherSettings;
  leftSidebar = CAD.leftSidebar;
  undoSystem = CAD.sketch.undoSystem;
  SelectionWidget = CAD.appState.SelectionWidget;
  ticker();
  solveSketch = CAD.sketch.solveSketch;
  console.log(solveSketch);
}


let currentZoom = 1; // This is the current zoom level
let initialHeight = window.innerHeight;


const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
svg.id = "canvas";
document.body.appendChild(svg);

function toggleShowSVG() {

  if (svg.style.display === "none") {
    svg.style.display = "block";
  } else {
    svg.style.display = "none";
  }
}

let phantomGeometryThickness;

let isPanning = false;
let startX = 0;
let startY = 0;
const viewBox = {
  x: 0,
  y: 0,
  width: window.innerWidth, // Set to window's width
  height: window.innerHeight, // Set to window's height
  update: function () {
    this.x = roundToDecimals(this.x, 4);
    this.y = roundToDecimals(this.y, 4);
    this.width = roundToDecimals(this.width, 4);
    this.height = roundToDecimals(this.height, 4);

    svg.setAttribute(
      "viewBox",
      `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`
    );
  }
};



svg.addEventListener("pointerdown", (e) => {
  removeDimensionEntryTextBox();
  if (e.button === 2) {
    // Right click
    startX = e.clientX;
    startY = e.clientY;
    isPanning = true;
  }
});


svg.addEventListener("pointermove", (e) => {
  if (isPanning) {
    let dx = e.clientX - startX;
    let dy = e.clientY - startY;
    //console.log(dx, dy, viewBox);

    viewBox.x -= (dx * (viewBox.width / window.innerWidth));
    viewBox.y -= (dy * (viewBox.height / window.innerHeight));

    viewBox.update();

    startX = e.clientX;
    startY = e.clientY;

    statusBarMessage(`Pan: ${viewBox.x}, ${viewBox.y}`);
  }
});


svg.addEventListener("click", async (e) => {
  removeDimensionEntryTextBox();
  if (CAD.appState.mode === "createPoint" || CAD.appState.mode === "createGeometry") {
    let makeGeometryWorked;
    let exitFlag = 0;
    while (CAD.appState.mode === "createGeometry" & exitFlag < 2) {
      exitFlag++;
      makeGeometryWorked = await CAD.sketch.createGeometry(CAD.appState.type);
      console.log(makeGeometryWorked, exitFlag);
      if (makeGeometryWorked === true) {
        console.log("geometry creation suceedded ")
        SelectionWidget.clearItems();
        return updateCanvas();
      }
      if (exitFlag === 1) await addPoint(e);
    }

    if (CAD.appState.mode === "createPoint") {
      await addPoint(e);
    }

  } else {
    SelectionWidget.clearItems();
  }
  updateCanvas();
});


function createPointOnClick() {
  CAD.appState.setMode("createPoint");
  CAD.appState.setType("")
  statusBarMessage("Click on the canvas to create a point");
}

svg.addEventListener("pointerup", () => {
  isPanning = false;
});

svg.addEventListener("wheel", (e) => {
  e.preventDefault();
  const zoomFactor = 1.1;

  // Get mouse coordinates relative to the SVG element
  const rect = svg.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;

  // Convert mouse coordinates to SVG coordinates
  const svgPoint = svg.createSVGPoint();
  svgPoint.x = x;
  svgPoint.y = y;
  const pointInSVG = svgPoint.matrixTransform(svg.getScreenCTM().inverse());

  // Calculate new viewBox dimensions
  let newWidth, newHeight;
  if (e.deltaY > 0) {
    newWidth = viewBox.width * zoomFactor;
    newHeight = viewBox.height * zoomFactor;
  } else {
    newWidth = viewBox.width / zoomFactor;
    newHeight = viewBox.height / zoomFactor;
  }

  // Maintain the original aspect ratio
  const originalAspectRatio = window.innerWidth / window.innerHeight;
  const newAspectRatio = newWidth / newHeight;

  if (newAspectRatio > originalAspectRatio) {
    newHeight = newWidth / originalAspectRatio;
  } else {
    newWidth = newHeight * originalAspectRatio;
  }

  // Calculate new viewBox origin
  viewBox.x += (pointInSVG.x - viewBox.x) - (newWidth * (pointInSVG.x - viewBox.x)) / viewBox.width;
  viewBox.y += (pointInSVG.y - viewBox.y) - (newHeight * (pointInSVG.y - viewBox.y)) / viewBox.height;

  // Update viewBox dimensions
  viewBox.width = newWidth;
  viewBox.height = newHeight;

  viewBox.update();

  statusBarMessage(`Zoom: ${viewBox.width}, ${viewBox.height}`);

  updateCanvas();
});



function zoomFitAll() {
  let minX = Math.min(...window.sketchObject.points.map((p) => p.x));
  let maxX = Math.max(...window.sketchObject.points.map((p) => p.x));
  let minY = Math.min(...window.sketchObject.points.map((p) => p.y));
  let maxY = Math.max(...window.sketchObject.points.map((p) => p.y));

  const originalAspectRatio = window.innerWidth / window.innerHeight;

  let newWidth = maxX - minX + 200;
  let newHeight = maxY - minY + 200;

  // Maintain the original aspect ratio
  const newAspectRatio = newWidth / newHeight;

  if (newAspectRatio > originalAspectRatio) {
    newHeight = newWidth / originalAspectRatio;
  } else {
    newWidth = newHeight * originalAspectRatio;
  }

  viewBox.x = minX - (newWidth - (maxX - minX)) / 2;
  viewBox.y = minY - (newHeight - (maxY - minY)) / 2;
  viewBox.width = newWidth;
  viewBox.height = newHeight;

  viewBox.update();
  updateCanvas();
}



// Prevent context menu from showing on right click
svg.addEventListener("contextmenu", (e) => {
  e.preventDefault();
});

let selectedPoint = null;
let selectedConstraint = null;

async function startDrag(e) {
  await sketch.solvePause();
  undoSystem.saveUndoState();

  selectItem(e);
  //console.log(e.target.getAttribute("data-type"));
  if (e.target.getAttribute("data-type") === "point") {
    selectedPoint = window.sketchObject.points.find((p) => p.id === parseInt(e.target.getAttribute("data-id")));
    selectedPoint.moving = true;
    //console.log("Selecting thus point", selectedPoint)



    svg.addEventListener("pointermove", drag);
    svg.addEventListener("pointerup", endDrag);
    sketch.solveResume();
    return;
  }

  if (e.target.getAttribute("data-type") === "geometry") {
    startGeometryDrag(e);
    sketch.solveResume();
    return;
  }

  if (e.target.getAttribute("data-type") === "constraint") {
    //console.log("Starting constraint drag");
    startConstraintDrag(e);
    sketch.solveResume();
    return;
  }
  sketch.solveResume();
  updateCanvas();
}


async function startConstraintDrag(e) {
  await sketch.solvePause();
  undoSystem.saveUndoState();
  selectItem(e);
  //removeDimensionEntryTextBox();
  selectedConstraint = window.sketchObject.constraints.find((c) => c.id === parseInt(e.target.getAttribute("data-id")));
  const point = svg.createSVGPoint();
  point.x = e.clientX;
  point.y = e.clientY;
  selectedConstraint.startMovePosition = point.matrixTransform(svg.getScreenCTM().inverse());
  svg.addEventListener("pointermove", drag);
  svg.addEventListener("pointerup", endDrag);
  //updateCanvas();
}




let selectedGeometry = null;
let selectedGeometryStartMovePosition = null;

function startGeometryDrag(e) {
  //sketch.solvePause();
  undoSystem.saveUndoState();
  //console.log("we are selecting ", e);
  selectItem(e);
  removeDimensionEntryTextBox();
  selectedGeometry = window.sketchObject.geometries.find((g) => g.id === parseInt(e.target.getAttribute("data-id")));
  selectedGeometry.moving = true;
  const point = svg.createSVGPoint();
  point.x = e.clientX;
  point.y = e.clientY;
  selectedGeometryStartMovePosition = point.matrixTransform(svg.getScreenCTM().inverse());
  svg.addEventListener("pointermove", drag);
  svg.addEventListener("pointerup", endDrag);

}

function drag(e) {
  e.stopPropagation();
  removeDimensionEntryTextBox();
  const point = svg.createSVGPoint();
  point.x = e.clientX;
  point.y = e.clientY;
  const newPointLocation = point.matrixTransform(svg.getScreenCTM().inverse());

  if (selectedPoint) {
    // Dragging a point
    if (isPointFixed(selectedPoint.id)) return; // Exit the function if the point is fixed.

    selectedPoint.x = newPointLocation.x;
    selectedPoint.y = newPointLocation.y;

    window.sketchObject.points.find((p) => p.id === selectedPoint.id).x = newPointLocation.x;
    window.sketchObject.points.find((p) => p.id === selectedPoint.id).y = newPointLocation.y;
    sketch.solveResume();
  } else if (selectedGeometry) {
    // Dragging a geometry entity
    const geometryPoints = selectedGeometry.points.map((pointId) => window.sketchObject.points.find((p) => p.id == pointId));


    const dx = newPointLocation.x - selectedGeometryStartMovePosition.x;
    const dy = newPointLocation.y - selectedGeometryStartMovePosition.y;

    selectedGeometryStartMovePosition = newPointLocation;

    geometryPoints.forEach((point) => {
      if (isPointFixed(point.id)) return; // Exit the function if the point is fixed.
      point.x += dx;
      point.y += dy;
    });
    sketch.solveResume();
  } else if (selectedConstraint) {
    // Dragging a constraint
    //console.log(selectedConstraint);

    const constraint = window.sketchObject.constraints.find((c) => c.id === parseInt(selectedConstraint.id));
    try {
      const dx = newPointLocation.x - constraint.startMovePosition.x;
      const dy = newPointLocation.y - constraint.startMovePosition.y;
    } catch (e) {
      console.log(e);
    }

    constraint.startMovePosition = newPointLocation;
    // get the points associated with the constraint
    const points = constraint.points.map((pointId) => window.sketchObject.points.find((p) => p.id == pointId));
    // check if dimension constraint
    if (constraint.type === "⟺") constraint.dimensionOffset = calcPerpendicularDistanceLineToPoint(points, { x: newPointLocation.x, y: newPointLocation.y });
    if (constraint.type === "∠") {
      // calculate center point of the angle and set the dimension offset to the distance from the center point to the mouse
      constraint.dimensionOffset = distance(constraint.intersectionPoint, newPointLocation);
    }


  }

  updateCanvas();
}


function endDrag(e) {
  sketch.solveResume();
  if (selectedPoint) {
    selectedPoint = null;
  } else if (selectedGeometry) {
    selectedGeometry = null;
  } else if (selectedConstraint) {
    editConstraint(e);
  }
  svg.removeEventListener("pointermove", drag);
  svg.removeEventListener("pointerup", endDrag);

}


function getSvgElementsUnderCursor(event) {
  // Function to filter SVG shape elements
  const elements = svg.children;

  const x = event.clientX;
  const y = event.clientY;

  // Get all elements under the cursor
  const elementsUnderCursor = document.elementsFromPoint(x, y);

  // Filter to get only the SVG shape elements
  const svgElementsUnderCursor = filterSvgElements(elementsUnderCursor);

  return svgElementsUnderCursor;
}


function filterSvgElements(elements) {
  const uniqueElements = new Set();
  const uniqueResult = [];

  for (const el of elements) {
    const tagName = el.tagName;

    if (
      tagName === 'circle' ||
      tagName === 'rect' ||
      tagName === 'line' ||
      tagName === 'path' ||
      tagName === 'polygon' ||
      tagName === 'polyline' ||
      tagName === 'ellipse' ||
      tagName === 'g'
    ) {
      const type = el.getAttribute("data-type");
      const id = el.getAttribute("data-id");
      const uniqueKey = `${type}-${id}`;

      if (!uniqueElements.has(uniqueKey)) {
        uniqueElements.add(uniqueKey);
        uniqueResult.push({ type, id });
      }
    }
  }

  return uniqueResult;
}





function isPointFixed(pointId) {
  return window.sketchObject.constraints.some(
    (constraint) => constraint.type === "⏚" && constraint.points[0] === pointId
  );
}



function getNextConstraintId() {
  return Math.max(...window.sketchObject.constraints.map((c) => c.id)) + 1;
}

window.tickerPaused = false;


let lastTick = 0;
let lastSave = 0;
async function ticker() {
  const now = new Date().getTime();

  if (lastTick === 0) {
    lastTick = now;
    lastSave = now;
  }

  lastTick = new Date().getTime();
  if (now - lastSave > CAD.settings.sketcherSettings.autoSave.interval && CAD.settings.sketcherSettings.autoSave.enabled_bool) {
    lastSave = now;
    CAD.sketch.fileIO.saveSketch();
  }

  try {
    //if (CAD.leftSidebar.leftDiv.getAttribute("data-hover") == "true") { sketch.solvePause(); }

    if (window.tickerPaused == false) {
      //sketch.solvePause();
      //console.log("solving and rendering");
      try {
        //updateCanvas();
        await solveSketch();
        if (window.sketchObject.needNewRenderFlag) {
          await updateCanvas();
        }
      } catch (e) {
        console.log(e);
      }
      sketch.solveResume();
    } else {
      await updateCanvas();
      console.log("skipping solve and render because ticker is paused.");
    }
    setTimeout(ticker, CAD.settings.sketcherSettings.solver.animate_Speed);
  } catch (e) {
    console.log(e);
    setTimeout(ticker, CAD.settings.sketcherSettings.solver.animate_Speed);
    await updateCanvas();
  }
}




function updateCanvas() {
  while (svg.firstChild) {
    svg.removeChild(svg.firstChild);
  }

  viewBox.update();
  svg.appendChild(svgSymbols.arrowheadStart);
  svg.appendChild(svgSymbols.arrowheadEnd);
  /// update the zoom level
  currentZoom = viewBox.height / initialHeight;

  // make a svg g element
  let svgPoints = makeSVGGroup();
  let svgConstraints = makeSVGGroup();
  let svgGeometries = makeSVGGroup();
  let svgConstraintsLabels = makeSVGGroup();
  let svgConstraintsLabelBackgroundBox = makeSVGGroup();
  svg.appendChild(svgConstraints);
  svg.appendChild(svgConstraintsLabelBackgroundBox);
  svg.appendChild(svgGeometries);
  svg.appendChild(svgConstraintsLabels);
  svg.appendChild(svgPoints);

  updateConstraintLabels(); // Figure out the locations to display constraint symbols on canvas.
  leftSidebar.clearLeftSidebar(); // Clear the left div
  window.sketchObject.constraints.forEach((constraint) => {
    try {
      /// make a div to display the constraint
      /// using the constraint type, value and points
      let constraintDiv = leftSidebar.makeSidebarItem({
        style: {
          borderColor: (constraint.error) ? sketcherSettings.constraints.error_color : sketcherSettings.constraints.color,
        },
        innerHTML: "C:" + constraint.id + " " + constraint.type + " " + (constraint.value ? "=" + constraint.value : '') + "  [" + constraint.points.join(", ") + "] ",
        "data-id": constraint.id,
        "data-type": "constraint",
      });
      //if (constraint.error) alert(constraint.error);
      constraintDiv.title = (constraint.error) ? constraint.error : "";

      constraintDiv.addEventListener("click", selectItem);
      constraintDiv.addEventListener("dblclick", editConstraint);
      //leftDiv.appendChild(constraintDiv);


      const points = constraint.points.map((pointId) => window.sketchObject.points.find((p) => p.id == pointId));
      const geometryEntityGroup = makeSVGGroup();
      geometryEntityGroup.setAttribute("data-id", constraint.id);
      geometryEntityGroup.setAttribute("data-type", "constraint");
      geometryEntityGroup.addEventListener("pointerdown", startConstraintDrag);
      geometryEntityGroup.addEventListener("click", selectItem);
      geometryEntityGroup.addEventListener("dblclick", editConstraint);
      svgConstraints.appendChild(geometryEntityGroup);

      let skipDrawingConstraintLines = false;

      let labelText = constraint.type;
      if (constraint.type === "⟺") {
        skipDrawingConstraintLines = true;
        labelText = "" + constraint.value;
        const offsetPoints = offsetLine(points, constraint.dimensionOffset ? constraint.dimensionOffset : 20 * currentZoom);

        constraint.labelX = (offsetPoints[0].x + offsetPoints[1].x) / 2;
        constraint.labelY = (offsetPoints[0].y + offsetPoints[1].y) / 2;

        const dimLine = svgSymbols.createConstraintLine(offsetPoints, constraint, true);
        const dimExtensionLine1 = svgSymbols.createConstraintLine([points[0], offsetPoints[0]], constraint, false);
        const dimExtensionLine2 = svgSymbols.createConstraintLine([points[1], offsetPoints[1]], constraint, false);
        geometryEntityGroup.appendChild(dimLine);
        geometryEntityGroup.appendChild(clonePhantom(dimLine));
        geometryEntityGroup.appendChild(dimExtensionLine1);
        geometryEntityGroup.appendChild(clonePhantom(dimExtensionLine1));
        geometryEntityGroup.appendChild(dimExtensionLine2);
        geometryEntityGroup.appendChild(clonePhantom(dimExtensionLine2));
      }
      // point on line constraint
      if (constraint.type === "⏛") {
        const pointOnLine1 = svgSymbols.createConstraintLine([points[0], points[1]], constraint, false);
        const pointOnLine2 = svgSymbols.createConstraintLine([points[0], points[2]], constraint, false);
        pointOnLine1.setAttribute("stroke-dasharray", "5,5");
        pointOnLine2.setAttribute("stroke-dasharray", "5,5");
        geometryEntityGroup.appendChild(pointOnLine1);
        geometryEntityGroup.appendChild(clonePhantom(pointOnLine1));
        geometryEntityGroup.appendChild(pointOnLine2);
        geometryEntityGroup.appendChild(clonePhantom(pointOnLine2));
      }


      //angle constraint
      if (constraint.type === "∠") {
        skipDrawingConstraintLines = false;
        labelText = constraint.value + "°";
        const average = averagePoint(points);
        //console.log(average);
        constraint.labelX = average.x;
        constraint.labelY = average.y;

        constraint.dimensionOffset = constraint.dimensionOffset ? Math.abs(constraint.dimensionOffset) : 20 * currentZoom;

        const angleLine1 = [points[0], points[1]];
        const angleLine2 = [points[2], points[3]];

        const angleArc = svgSymbols.drawAngleDimensionArc([angleLine1, angleLine2], constraint);
        constraint.labelX = angleArc.midpoint.x;
        constraint.labelY = angleArc.midpoint.y;

        geometryEntityGroup.appendChild(angleArc.arc);
        geometryEntityGroup.appendChild(clonePhantom(angleArc.arc));



        // create lines from the intersection of the 2 lines to the start and end of the arc
        let intersectionPoint;
        try {
          intersectionPoint = lineIntersection(angleLine1, angleLine2);
          if (intersectionPoint === null) intersectionPoint = { x: average.x, y: average.y };
        } catch {
          intersectionPoint = { x: average.x, y: average.y }
        }



        const line1 = findArcIntersections(intersectionPoint, constraint.dimensionOffset, angleLine1);
        const line2 = findArcIntersections(intersectionPoint, constraint.dimensionOffset, angleLine2);

        constraint.intersectionPoint = intersectionPoint;

        const line1Extension = svgSymbols.createConstraintLine(line1, constraint, false);
        line1Extension.setAttribute("stroke-dasharray", "5,5");
        geometryEntityGroup.appendChild(line1Extension);
        geometryEntityGroup.appendChild(clonePhantom(line1Extension));

        const line2Extension = svgSymbols.createConstraintLine(line2, constraint, false);
        line2Extension.setAttribute("stroke-dasharray", "5,5");
        geometryEntityGroup.appendChild(line2Extension);
        geometryEntityGroup.appendChild(clonePhantom(line2Extension));
      }
      //console.log(currentZoom);
      let label = document.createElementNS("http://www.w3.org/2000/svg", "text");
      label.textContent = labelText;
      label.setAttribute("x", constraint.labelX);
      label.setAttribute("y", constraint.labelY);
      label.setAttribute("fill", (constraint.error) ? sketcherSettings.constraints.error_color : sketcherSettings.constraints.color);
      label.setAttribute("text-anchor", "middle");
      label.setAttribute("alignment-baseline", "middle");
      label.setAttribute("data-id", constraint.id);
      label.setAttribute("data-type", "constraint");
      label.setAttribute("vector-effect", "non-scaling-stroke");
      label.addEventListener("click", selectItem);
      label.addEventListener("pointerdown", startConstraintDrag);
      label.setAttribute("font-size", sketcherSettings.constraints.fontSize * currentZoom);
      svgConstraintsLabels.prepend(label);

      let rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      rect.setAttribute("fill", sketcherSettings.background.color);
      rect.setAttribute("stroke", "black");
      rect.setAttribute("stroke-width", 1 * currentZoom);
      rect.setAttribute("data-id", constraint.id);
      rect.setAttribute("data-type", "constraint");
      rect.addEventListener("click", selectItem);
      rect.addEventListener("pointerdown", startConstraintDrag);
      svgConstraintsLabelBackgroundBox.prepend(rect);


      rect.setAttribute("width", label.getBBox().width);
      rect.setAttribute("height", label.getBBox().height);
      rect.setAttribute("x", label.getAttribute("x") - (label.getBBox().width / 2));
      rect.setAttribute("y", label.getAttribute("y") - (label.getBBox().height / 2));


      /// do a for each on the constraint points and load the point data in to an array

      /// calculate the average x and y of the points
      let averageX = 0;
      let averageY = 0;

      constraint.points.forEach((point) => {
        let constraintPoint = window.sketchObject.points.find((p) => p.id === point);
        averageX += constraintPoint.x;
        averageY += constraintPoint.y;
      });

      averageX /= constraint.points.length;
      averageY /= constraint.points.length;


      if (!skipDrawingConstraintLines) {
        constraint.points.forEach((point) => {
          let constraintPoint = window.sketchObject.points.find((p) => p.id === point);

          if (constraintPoint !== undefined) {
            const line1 = [constraintPoint, { x: constraint.labelX, y: constraint.labelY }];
            if (constraint.type === "━" || constraint.type === "│") {
              line1[1] = { x: averageX, y: averageY };
            }
            if (constraint.type === "⟂" || constraint.type === "∠") {
              line1[1] = { x: constraint.intersectionPoint.x, y: constraint.intersectionPoint.y };
            }
            const line = svgSymbols.createConstraintLine(line1, constraint, false);
            line.setAttribute("stroke-dasharray", "5,5");
            line.addEventListener("click", selectItem);
            svgConstraints.appendChild(line);
            const phantomLine = clonePhantom(line);
            phantomLine.addEventListener("click", selectItem);
            svgConstraints.appendChild(phantomLine);
          }

        });
      }



    } catch (e) {
      console.log(e);
      console.log(`Error while adding constraint for constraint ID: ${constraint.id}`);
    }
  });



  // Render geometries so that they are on top of the constraints.

  phantomGeometryThickness = sketcherSettings.geometry.thickness * 10;

  window.sketchObject.geometries.forEach((geo) => {
    // get the points for each point id in the geo.points array.

    const geometryEntity = leftSidebar.makeSidebarItem({
      style: {
        borderColor: sketcherSettings.geometry.color,
      },
      innerHTML: `${geo.type}:${geo.id} [${geo.points.join(", ")}]`,
      "data-id": geo.id,
      "data-type": "geometry",
    });
    geometryEntity.addEventListener("click", selectItem);

    const geometryPoints = geo.points.map((pointId) => window.sketchObject.points.find((p) => p.id == pointId));
    const geometryEntityGroup = makeSVGGroup();
    geometryEntityGroup.setAttribute("data-id", geo.id);
    geometryEntityGroup.setAttribute("data-type", "geometry");
    geometryEntityGroup.addEventListener("click", selectItem);
    geometryEntityGroup.addEventListener("pointerdown", startGeometryDrag);
    svgGeometries.appendChild(geometryEntityGroup);

    let pathToMake = null;

    if (geo.type === "line") {
      pathToMake = svgSymbols.createLine(geometryPoints, geo.id, "geometry", sketcherSettings.geometry.color, sketcherSettings.geometry.thickness);
    }

    if (geo.type === "circle") {
      pathToMake = svgSymbols.createCircle(geometryPoints, geo.id, "geometry", sketcherSettings.geometry.color, sketcherSettings.geometry.thickness);
    }

    if (geo.type === "arc") {
      pathToMake = svgSymbols.createArc(geometryPoints, geo.id, "geometry", sketcherSettings.geometry.color, sketcherSettings.geometry.thickness);
    }

    if (pathToMake === null) return;
    if (geo.construction) pathToMake.setAttribute("stroke-dasharray", "5,5");

    const phantomClone = clonePhantom(pathToMake);
    geometryEntityGroup.appendChild(phantomClone);
    geometryEntityGroup.appendChild(pathToMake);

  });



  // Render points last so they appear on top

  window.sketchObject.points.forEach((point) => {
    let adjustedRadius = (sketcherSettings.points.size * currentZoom) / 2;

    const pointDiv = leftSidebar.makeSidebarItem({
      style: {
        borderColor: sketcherSettings.points.color,
      },
      innerHTML: "Point " + point.id + " (" + point.x + ", " + point.y + ")",
      "data-id": point.id,
      "data-type": "point",
    });
    pointDiv.addEventListener("click", selectItem);
    pointDiv.addEventListener("dblclick", setPointXY);


    const pointGroupThing = makeSVGGroup();
    pointGroupThing.addEventListener("dblclick", setPointXY);
    pointGroupThing.addEventListener("click", selectItem); // Add click listener for selection
    pointGroupThing.addEventListener("pointerdown", startDrag);

    svgPoints.appendChild(pointGroupThing);

    let pointSymbol = svgSymbols.createPoint(point, adjustedRadius, sketcherSettings.points.color, sketcherSettings.points.size,);
    let phantom = clonePhantom(pointSymbol);

    pointGroupThing.appendChild(phantom);
    pointGroupThing.appendChild(pointSymbol);
  });

  SelectionWidget.updateWidget();

  SelectionWidget.getItems().forEach((selected) => {
    //find all items that match query selector and set the color to selected
    document.querySelectorAll(
      `[data-id='${selected.id}'][data-type='${selected.type}']`
    ).forEach((item) => {
      if (item) {
        if (item.tagName === "DIV") {
          if (item.parentElement.id !== "selectionWidget") {
            item.style.borderColor = sketcherSettings.selected.color;
          } else {
            item.style.borderColor = item.style.borderColor;
          }
        }
        else if (item.tagName.toUpperCase() === "TEXT") {
          item.setAttribute("fill", sketcherSettings.selected.color);
        } else {
          item.setAttribute("stroke", sketcherSettings.selected.color);

          item.setAttribute("stroke-width", item.getAttribute("stroke-width") * 2);
        }
      }
    });
  });
  // ;
}

let lastClickTime = 0;
function selectItem(e) {
  // set lastClickTime to the current time in milliseconds
  let clickType = "single";
  const thisClickTime = new Date().getTime();
  if (thisClickTime - lastClickTime < 300) clickType = "double";
  lastClickTime = new Date().getTime();
  //console.log(lastClickTime);

  e.preventDefault();
  e.stopPropagation(); // Prevent multiple items from being selected at once


  let item = e.target;
  let itemId = item.getAttribute("data-id");
  let itemType = item.getAttribute("data-type");

  let selectedItems = SelectionWidget.getItems()

  if (selectedItems.some((selected) => selected.id === itemId && selected.type === itemType)) {
    //If item is already selected, deselect it
    SelectionWidget.replaceItems(selectedItems.filter(
      (selected) => !(selected.id === itemId && selected.type === itemType)
    ));
  } else {
    SelectionWidget.addItem({ id: itemId, type: itemType });
  }


  //SelectionWidget.addItem({ id: itemId, type: itemType });

  updateCanvas();
  if (e.target.tagName === "DIV" && clickType === "double") {
    return editConstraint(e);
  };
}






async function setPointXY(e) {
  e.stopPropagation();
  let point = window.sketchObject.points.find((p) => p.id === parseInt(e.target.getAttribute("data-id")));
  let x = await prompt("Enter the new X coordinate for the point", point.x);
  let y = await prompt("Enter the new Y coordinate for the point", point.y);
  if (x !== null && y !== null) {
    point.x = parseFloat(x);
    point.y = parseFloat(y);
    //console.log(point, window.sketchObject.points);
    updateCanvas();
  }
}

document.addEventListener("keydown", async (e) => {
  await sketch.solvePause();
  if (e.key === "Escape") {
    SelectionWidget.clearItems();
    CAD.appState.setType("");
    updateCanvas();
    removeDimensionEntryTextBox();
  }
  if (e.key === "Delete" || e.key === "Backspace") {
    await sketch.solvePause();
    if (e.target === dimensionEntryTextBox) return; // Don't delete if the user is typing in the dimension entry box
    removeDimensionEntryTextBox();

    undoSystem.saveUndoState();


    let points = (await SelectionWidget.getItems("point")).forEach((point) => { console.log("deleting", point); sketch.removePointById(point.id) });

    let geometriesRemoved = SelectionWidget.getItems("geometry")?.forEach((geo) => { sketch.removeGeometryById(geo.id) });
    let constraintsRemove = SelectionWidget.getItems("constraint")?.forEach((constraint) => { sketch.removeConstraintById(constraint.id) });
    SelectionWidget.clearItems();
    await sketch.solveResume();
  }


  if (e.ctrlKey && e.key === "z") {
    undoSystem.undo();
  }
  else if (e.ctrlKey && e.key === "y") {
    undoSystem.redo();
  }

  updateCanvas();
});






async function addPoint(e) {
  // Remove the event listener so that only one point is added
  console.log("adding point");
  e.preventDefault();
  undoSystem.saveUndoState();


  const point = svg.createSVGPoint();
  point.x = e.clientX;
  point.y = e.clientY;
  const newPointLocation = point.matrixTransform(svg.getScreenCTM().inverse());



  // Find the highest ID among the existing points
  let highestId = Math.max(...window.sketchObject.points.map((p) => p.id));
  if (highestId === -Infinity) highestId = 0; // If there are no points, set the highest ID to 0 (this is the first point

  let newPoint = {
    id: highestId + 1,
    x: newPointLocation.x,
    y: newPointLocation.y,
  };

  await window.sketchObject.points.push(newPoint);
  SelectionWidget.addItem({ id: newPoint.id, type: "point" });

  if (CAD.appState.mode === "createPoint") {
    CAD.appState.mode = "select";
    //alert(CAD.appState.mode);
  }
  updateCanvas();
}

function updateConstraintLabels() {
  window.sketchObject.constraints.forEach((constraint) => {
    if (constraint.type === "⟺") return; // Skip distance constraints
    constraint.labelX = 0;
    constraint.labelY = 0;


    let point1 = window.sketchObject.points.find((p) => p.id === constraint.points[0]);
    let point2 = window.sketchObject.points.find((p) => p.id === constraint.points[1]);
    let point3 = window.sketchObject.points.find((p) => p.id === constraint.points[2]);
    let point4 = window.sketchObject.points.find((p) => p.id === constraint.points[3]);

    constraint.labelX = point1.x;
    constraint.labelY = point1.y;


    if (constraint.type === "━" || constraint.type === "│") {
      // Update the label position
      constraint.labelX = (point1.x + point2.x) / 2;
      constraint.labelY = (point1.y + point2.y) / 2;

      if (constraint.type === "│") constraint.labelX -= 10 * currentZoom;
      if (constraint.type === "━") constraint.labelY -= 10 * currentZoom;

    }

    if (constraint.type === "⟂" || constraint.type === "∠") {
      let linesIntersectionPoint = null;
      let labelsIntersectionPoint = null;
      try {
        // calculate intersection point of the 2 lines formed by the points
        linesIntersectionPoint = getIntersectionPoint(point1, point2, point3, point4, 0);
        // calculate the points for a line parallel to the first line but offset by 12 pixels
        labelsIntersectionPoint = getIntersectionPoint(point1, point2, point3, point4, 12 * currentZoom);

      } catch (e) {

      }

      if (linesIntersectionPoint === null || labelsIntersectionPoint === null) {
        //console.log("intersection failed. using average point", point1, point2, point3, point4);
        linesIntersectionPoint = { x: (point1.x + point2.x) / 2, y: (point1.y + point2.y) / 2 };
        labelsIntersectionPoint = { x: (point1.x + point2.x) / 2, y: (point1.y + point2.y) / 2 };
      }



      constraint.labelX = labelsIntersectionPoint.x;
      constraint.labelY = labelsIntersectionPoint.y;
      constraint.intersectionPoint = linesIntersectionPoint;


    }

    // parallel constraint type
    if (constraint.type === "∥" || constraint.type === "⇌") {
      // Update the label position
      // average the x and y values of the 4 points
      //console.log(constraint, point1, point2, point3, point4);
      constraint.labelX = (point1.x + point2.x + point3.x + point4.x) / 4;
      constraint.labelY = (point1.y + point2.y + point3.y + point4.y) / 4;

    }

    if (constraint.type === "⋯") {
      // position the symbol next to point3
      constraint.labelX = point3.x + (20 * currentZoom);
      constraint.labelY = point3.y + (20 * currentZoom);
    }


    if (constraint.type === "⏛") {
      // chose what point to use as the label position
      // this constraint has 3 points
      let distance1 = distance(point1, point2);
      let distance2 = distance(point1, point3);
      let distance3 = distance(point2, point3);

      // determin what the longest distance is and what 2 points it is between

      // Update the label position
      if (distance1 > distance2 && distance1 > distance3) {
        constraint.labelX = point3.x;
        constraint.labelY = point3.y;
      }
      if (distance2 > distance1 && distance2 > distance3) {
        constraint.labelX = point2.x;
        constraint.labelY = point2.y;
      }
      if (distance3 > distance1 && distance3 > distance2) {
        constraint.labelX = point1.x;
        constraint.labelY = point1.y;
      }

      /// test if the line formed by the points is more horizontal or vertical
      let isHorizontal = Math.abs(point1.x - point2.x) > Math.abs(point2.y - point3);

      if (isHorizontal) {
        constraint.labelY -= 15 * currentZoom;
        constraint.labelX -= 15 * currentZoom;
      } else {  // Vertical line  
        constraint.labelX += 15 * currentZoom;
        constraint.labelY -= 15 * currentZoom;
      }
    }


    if (constraint.type === "⏚") {
      constraint.labelX = point1.x + (-10 * currentZoom);
      constraint.labelY = point1.y - (10 * currentZoom);
      let point = window.sketchObject.points.find((p) => p.id === constraint.point1);
    }
    if (constraint.type === "≡") {
      constraint.labelX = point1.x + (10 * currentZoom);
      constraint.labelY = point1.y - (10 * currentZoom);
      let point = window.sketchObject.points.find((p) => p.id === constraint.point1);
    }
  });
}

var dimensionEntryTextBox = null;
async function removeDimensionEntryTextBox(setToPaused = false) {
  if (dimensionEntryTextBox) {
    dimensionEntryTextBox.remove();
    dimensionEntryTextBox = null;
  }
  //if (setToPaused == true) sketch.solvePause();
}


function editConstraint(e) {

  console.log("editing constraint")
  //e.stopPropagation(); // Prevent multiple items from being selected at once
  let itemId = e.target.getAttribute("data-id");
  /// find the constraint in the sketchObject
  let constraint = window.sketchObject.constraints.find((c) => c.id === parseInt(itemId));

  //distance or angle constraint 
  if (constraint && constraint.type &&
    (constraint.type === "⟺" || constraint.type === "∠") &&
    (e.target.tagName === "text" || e.target.tagName === "rect" || e.target.tagName === "DIV")
  ) {
    removeDimensionEntryTextBox();
    sketch.solvePause();

    const point = { x: constraint.labelX, y: constraint.labelY };
    dimensionEntryTextBox = document.createElement('input');

    dimensionEntryTextBox.setAttribute("data-id", constraint.id);
    dimensionEntryTextBox.value = constraint.value;

    // center the text in the input box
    dimensionEntryTextBox.style.textAlign = "center";
    dimensionEntryTextBox.addEventListener('keydown', async function (e) {
      if (e.key === 'Enter') {
        await updateCanvas();
        await sketch.solvePause();
        console.log("value has been changed to ", dimensionEntryTextBox.value);
        let itemId = dimensionEntryTextBox.getAttribute("data-id");
        let constraint = await window.sketchObject.constraints.find((c) => c.id === parseInt(itemId));
        constraint.value = await dimensionEntryTextBox.value;
        await dimensionEntryTextBox.remove();
        dimensionEntryTextBox = null;
        await removeDimensionEntryTextBox();
        await sketch.solveResume();

        await sketch.solvePause();
        await sketch.solveResume();
        await sketch.solveSketch();

        await updateCanvas();
      }
      if (e.key === 'Escape') {
        dimensionEntryTextBox.remove();
        dimensionEntryTextBox = null;
        removeDimensionEntryTextBox();
        await sketch.solveResume();
        await sketch.solveSketch();
        await updateCanvas();
      }

    });

    document.body.appendChild(dimensionEntryTextBox);
    dimensionEntryTextBox.select();
    // find rect element in the svg and place the input box over it. it might be nested under some thing inside the svg.
    //const rect = document.querySelector(`[data-id='${constraint.id}'][data-type='constraint']`).querySelector("rect");

    const svgRoot = document.querySelector('svg'); // Replace with your SVG root element selector if different
    const rect = svgRoot.querySelector(`rect[data-id='${constraint.id}'][data-type='constraint']`);

    //console.log(getCornerCoordinates(rect));

    positionHTMLOverSVG(rect, dimensionEntryTextBox);
    sketch.solvePause();
  }
}




function positionHTMLOverSVG(svgElement, htmlElement) {
  // Check if the elements exist
  if (!svgElement || !htmlElement) {
    console.error("Element(s) not found");
    return;
  }

  // Get the bounding client rect
  const rect = svgElement.getBoundingClientRect();

  // Set the CSS position values
  htmlElement.style.position = "absolute";
  htmlElement.style.top = `${rect.top}px`;
  htmlElement.style.left = `${rect.left}px`;
  htmlElement.style.width = `${rect.width}px`;
  htmlElement.style.height = `${rect.height}px`;
}


//function to make a clone of an existing element
function clonePhantom(element) {
  const newElementToReturn = element.cloneNode(true);
  newElementToReturn.setAttribute("stroke", "yellow");
  newElementToReturn.setAttribute("stroke-width", phantomGeometryThickness);
  newElementToReturn.setAttribute("stroke-opacity", "0.01");
  newElementToReturn.setAttribute("marker-start", "");
  newElementToReturn.setAttribute("marker-end", "");
  newElementToReturn.setAttribute("stroke-dasharray", "");
  newElementToReturn.setAttribute("class", "phantomBackground");
  return newElementToReturn
}


//function to make an SVG group element
function makeSVGGroup() {
  return document.createElementNS("http://www.w3.org/2000/svg", "g");
}

window.updateCanvas = updateCanvas;

export const renderer = {
  setup,
  updateCanvas,
  zoomFitAll,
  selectItem,
  createPointOnClick,
  toggleShowSVG,
}