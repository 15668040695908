
const body = document.body;

/// create a textbox that will act as a status bar
let statusBar = document.createElement("input");
statusBar.id = "statusBar";
statusBar.style.position = "absolute";
statusBar.style.bottom = "0px";
statusBar.style.left = "0px";
statusBar.style.width = "100%";
statusBar.style.height = "30px";
statusBar.style.backgroundColor = "#f5f5f5"; // A light gray background
statusBar.style.borderTop = "1px solid #ccc"; // A border to separate it from the content
statusBar.style.zIndex = "1000"; // Ensure it's on top of other elements
body.appendChild(statusBar);





export function statusBarMessage(message, timeout = 5000) {
    statusBar.value = message;
    return
    setTimeout(() => {
        statusBar.value = "";
    }, timeout);
}
