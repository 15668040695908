import Worker from './sketch.worker.js';
const worker = new Worker();
export function workerCall(payload) {

    payload = JSON.parse(JSON.stringify(payload));
    // Create a new Worker instance

    return new Promise((resolve, reject) => {
        // Setup an event listener to handle the response from the worker
        worker.addEventListener('message', function handler(e) {
            //console.log("This is the data returned from the worker: ", e.data); 
            resolve(e.data); // Resolve the promise with the data from the worker
            worker.removeEventListener('message', handler); // Clean up the event listener
        });

        // Error handling
        worker.addEventListener('error', reject);

        // Send the message to the worker
        //console.log("sending payload to worker: ", payload);

        //payload.args = JSON.stringify(payload.args);

        worker.postMessage(payload);
    });
}

//workerCall("i'm ready");


//workerCall("i'm ready").then(console.log("I'm ready to work!")); // I'm ready to work!    