"use strict";

import { notifyUser } from "./notifications.js";
let fileNameField;

let updateCanvas;
export async  function saveSketch() {
  let fileName = fileNameField.value;
  if (fileName !== "") {
    localStorage.setItem(fileName, JSON.stringify(window.sketchObject));
    localStorage.setItem(fileName + ".tcl",await window.CAD.sketch.export_occt());
    notifyUser(`Sketch saved as "${fileName}"`, "info");
    //alert(`Sketch saved as "${fileName}"`);
  } else {
    notifyUser(`Please enter a file name`, "error");
    //alert(`Please enter a file name`);
  }
}

export function loadSketch() {
  let fileName = fileNameField.value;
  if (fileName !== "") {
    let loadedSketch = localStorage.getItem(fileName);
    if (loadedSketch) {
      window.sketchObject = JSON.parse(loadedSketch);
      notifyUser(`Sketch "${fileName}" loaded successfully!`, "info");
    } else {
      notifyUser(`No sketch found with the name "${fileName}"`, "error");
    }
  }
  CAD.renderer.updateCanvas(); // Update the canvas with the loaded sketch
  CAD.renderer.zoomFitAll();
}


function setup(CAD) {
  fileNameField = CAD.toolbarWidget.fileNameField;
}

export const fileIO = {
  setup,
  saveSketch,
  loadSketch
}

