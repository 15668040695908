"use strict";

import { settings } from "../settings.js";
import {
  calculateAngle,
  describeArc,
  findMidpointOnArc,
  lineIntersection,
  findArcIntersectionPoint,
  distance
} from "../solver/mathHelpersMod.js";
const sketcherSettings = settings.sketcherSettings;


const arrowheadStart = createArrowhead('arrowheadStart', '0', '10 0, 0 3.5, 10 7');
const arrowheadEnd = createArrowhead('arrowheadEnd', '10', '0 0, 10 3.5, 0 7');


function createArrowhead(id, refX, points) {
  const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
  const marker = document.createElementNS('http://www.w3.org/2000/svg', 'marker');
  marker.setAttribute('id', id);
  marker.setAttribute('markerWidth', '10');
  marker.setAttribute('markerHeight', '7');
  marker.setAttribute('refX', refX);
  marker.setAttribute('refY', '3.5');
  marker.setAttribute('orient', 'auto');
  marker.setAttribute("stroke", sketcherSettings.constraints.color);
  marker.setAttribute("fill", sketcherSettings.constraints.color);

  const polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
  polygon.setAttribute('points', points);

  marker.appendChild(polygon);
  defs.appendChild(marker);
  return defs;
}










// Function to draw the arc annotation
function drawAngleDimensionArc(lines, constraint) {
  const radius = constraint.dimensionOffset;
  const [line1, line2] = lines;
  let center = lineIntersection(line1, line2);
  if (!center) {
    // find the point on line1 and line2 that are closest to each other
    const distances = [];
    distances.push({ distance: distance(line1[0], line2[0]), points: [line1[0], line2[0]] });
    distances.push({ distance: distance(line1[0], line2[1]), points: [line1[0], line2[1]] });
    distances.push({ distance: distance(line1[1], line2[0]), points: [line1[1], line2[0]] });
    distances.push({ distance: distance(line1[1], line2[1]), points: [line1[1], line2[1]] });
    distances.sort((a, b) => a.distance - b.distance);




    center = {
      x: (distances[0].points[0].x + distances[0].points[1].x) / 2,
      y: (distances[0].points[0].y + distances[0].points[1].y) / 2,
    };




  }
  const svgNS = "http://www.w3.org/2000/svg";

  let startAngle = calculateAngle(line2[0], line2[1]);
  let endAngle = calculateAngle(line1[0], line1[1]);

  let arcPathString = describeArc(center.x, center.y, radius, startAngle, endAngle);

  const arc = document.createElementNS(svgNS, "path");
  arc.setAttribute("d", arcPathString);
  arc.setAttribute('marker-end', 'url(#arrowheadEnd)');
  arc.setAttribute('marker-start', 'url(#arrowheadStart)');

  arc.setAttribute("data-id", constraint.id);
  arc.setAttribute("data-type", "constraint");
  arc.setAttribute("stroke", sketcherSettings.constraints.color);
  arc.setAttribute("vector-effect", "non-scaling-stroke");
  arc.setAttribute("stroke-width", sketcherSettings.constraints.thickness);

  arc.setAttribute("fill", "none");
  const midX = (center.x + radius * Math.cos((startAngle + endAngle) / 2));
  const midY = (center.y + radius * Math.sin((startAngle + endAngle) / 2));

  return { arc, midpoint: findMidpointOnArc(center.x, center.y, radius, startAngle, endAngle) };
}


function createConstraintLine(points, constraint, addArrowhead = false) {
  let line = document.createElementNS("http://www.w3.org/2000/svg", "line");

  if (points[0].x === "NaN" || points[0].y === "NaN" || points[1].x === "NaN" || points[1].y === "NaN") {
    console.error("NaN");
    console.log(points);
    return;
  }
  line.setAttribute("x1", points[0].x);
  line.setAttribute("y1", points[0].y);
  line.setAttribute("x2", points[1].x);
  line.setAttribute("y2", points[1].y);
  line.setAttribute("data-id", constraint.id);
  line.setAttribute("data-type", "constraint");
  line.setAttribute("stroke", sketcherSettings.constraints.color);
  line.setAttribute("vector-effect", "non-scaling-stroke");
  line.setAttribute("stroke-width", sketcherSettings.constraints.thickness);

  if (addArrowhead) {
    line.setAttribute("marker-start", "url(#arrowheadStart)");
    line.setAttribute("marker-end", "url(#arrowheadEnd)");
  }
  return line;
}


function createLine(points, dataId, dataType, stroke, strokeWidth) {
  const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
  line.setAttribute("x1", points[0].x);
  line.setAttribute("y1", points[0].y);
  line.setAttribute("x2", points[1].x);
  line.setAttribute("y2", points[1].y);
  line.setAttribute("stroke", stroke);
  line.setAttribute("vector-effect", "non-scaling-stroke");
  line.setAttribute("stroke-width", strokeWidth);
  line.setAttribute("data-id", dataId);
  line.setAttribute("data-type", dataType);
  return line;
}

function createCircle(points, dataId, dataType, stroke, strokeWidth, fill = "none") {
  let radius = distance(points[0], points[1]);

  let circle = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "circle"
  );
  circle.setAttribute("cx", points[0].x);
  circle.setAttribute("cy", points[0].y);
  circle.setAttribute("r", radius);
  circle.setAttribute("stroke", stroke);
  circle.setAttribute("vector-effect", "non-scaling-stroke");
  circle.setAttribute("stroke-width", strokeWidth);
  circle.setAttribute("fill", fill);
  circle.setAttribute("data-id", dataId);
  circle.setAttribute("data-type", dataType);
  return circle;
}


function createArc(points, dataId, dataType, stroke, strokeWidth, fill = "none") {
  const [centerPoint, startPoint, endPoint] = points;
  const radius = distance(centerPoint, startPoint);

  let startAngle = calculateAngle(centerPoint, startPoint);
  let endAngle = calculateAngle(centerPoint, endPoint);
  const arcPath = describeArc(centerPoint.x, centerPoint.y, radius, startAngle, endAngle);

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", arcPath);
  path.setAttribute("stroke", stroke);
  path.setAttribute("fill", "none");
  path.setAttribute("vector-effect", "non-scaling-stroke");
  path.setAttribute("stroke-width", strokeWidth);
  path.setAttribute("data-id", dataId);
  path.setAttribute("data-type", dataType);
  return path;
}

function createPoint(point, adjustedRadius, stroke, strokeWidth) {
  let circle = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "circle"
  );
  circle.setAttribute("cx", point.x);
  circle.setAttribute("cy", point.y);
  circle.setAttribute("r", adjustedRadius); // Use the adjusted radius
  circle.setAttribute("vector-effect", "non-scaling-stroke");
  circle.setAttribute("stroke-width", strokeWidth);
  circle.setAttribute("stroke", stroke);
  circle.setAttribute("data-id", point.id);
  circle.setAttribute("data-type", "point");
  return circle;
}


export const svgSymbols = {
  arrowheadStart,
  arrowheadEnd,
  drawAngleDimensionArc,
  createConstraintLine,
  createLine,
  createCircle,
  createArc,
  createPoint,
}