"use strict";
import "./leftSidebar.css";



// Create a div on the left side of the page for displaying a list of constraints and points
let leftDiv = document.createElement("div");
leftDiv.id = "leftDiv";
leftDiv.classList.add("leftDiv");
leftDiv.setAttribute("data-hover", "false");
leftDiv.onmouseover = function () { this.style.opacity = 1; leftDiv.setAttribute("data-hover", "true"); };
leftDiv.onmouseout = function () { this.style.opacity = 0.8; leftDiv.setAttribute("data-hover", "false"); };
document.body.appendChild(leftDiv);

function makeSidebarItem(item) {
    let itemDiv = document.createElement("div");
    itemDiv.classList.add("sidebar-Item");
    copyAttributes(itemDiv, item);
    leftDiv.appendChild(itemDiv);
    restoreScrollPosition();
    return itemDiv;
}

function copyAttributes(destObj, srcObj, prefixSrc = '', prefixDest = '') {
    for (const key in srcObj) {
        if (srcObj.hasOwnProperty(key)) {
            const value = srcObj[key];
            const newPrefixSrc = prefixSrc ? `${prefixSrc}.${key}` : key;
            const newPrefixDest = prefixDest ? `${prefixDest}.${key}` : key;

            // Special handling for the 'style' property
            if (key === 'style') {
                for (const styleKey in value) {
                    if (value.hasOwnProperty(styleKey)) {
                        destObj.style[styleKey] = value[styleKey];
                    }
                }
            }
            // If the value is an object, call the function recursively
            else if (typeof value === 'object' && value !== null) {
                // Initialize the destination object's corresponding attribute if it doesn't exist
                if (!destObj.hasOwnProperty(key)) {
                    destObj[key] = {};
                }
                copyAttributes(value, destObj[key], newPrefixSrc, newPrefixDest);
            } else {
                // Copy the attribute value
                //console.log(newPrefixSrc, newPrefixDest, value);
                //destObj.setAttribute(key, value);
                if (key.includes("data-")) {
                    destObj.setAttribute(key, value);
                } else {
                    destObj[key] = value;
                }

            }
        }
    }
}

let scrollPosition = 0;

function storeScrollPosition() {
    scrollPosition = leftDiv.scrollTop;
}

function restoreScrollPosition() {
    leftDiv.scrollTop = scrollPosition;
}


function clearLeftSidebar() {
    storeScrollPosition();
    leftDiv.innerHTML = '';
}

export const leftSidebar = {
    leftDiv,
    makeSidebarItem,
    copyAttributes,
    storeScrollPosition,
    restoreScrollPosition,
    clearLeftSidebar,
}