let undoStack = [];
let redoState;
let updateCanvas;


 function undo() {
    redoState = JSON.stringify(window.sketchObject);
    //console.log("this is the current undo stack", undoStack)
    if (undoStack.length > 0) {
        window.sketchObject = JSON.parse(undoStack.pop());
        window.updateCanvas();
    }
}


 function redo() {
    if (redoState) {
        undoStack.push(JSON.stringify(window.sketchObject));
        window.sketchObject = JSON.parse(redoState);
        redoState = null;
        window.updateCanvas();
    }
}



 function saveUndoState() {
    //console.log("saving undo state");
    undoStack.push(JSON.stringify(window.sketchObject));
    redoState = null;

    /// max undo stack size is 100
    if (undoStack.length > 100) {
        undoStack.shift();
    }
}


export const undoSystem = {
    undo,
    redo,
    saveUndoState,
}