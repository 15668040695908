// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-Item {
  margin: 0px;
  color: rgb(255, 255, 255);
  display: flex;
  background-color: #0e0d0d;
  padding: 2px;
  height: 25px;
  line-height: 18px;
  /* Directly using the height value */
  font-size: 16px;
  /* Assuming a direct translation from height - 2px */
  border: 2px solid;
}

.leftDiv {
  position: absolute;
  top: 31px;
  left: 0px;
  max-width: 350px;
  max-height: calc(100% - 62px);
  min-height: 50px;
  overflow: auto;
  padding: 2px;
  box-sizing: border-box;
  border: 1px solid yellow;
  opacity: 0.8;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/leftSidebar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,oCAAoC;EACpC,eAAe;EACf,oDAAoD;EACpD,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,gBAAgB;EAChB,6BAA6B;EAC7B,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,sBAAsB;EACtB,wBAAwB;EACxB,YAAY;EACZ,aAAa;AACf","sourcesContent":[".sidebar-Item {\n  margin: 0px;\n  color: rgb(255, 255, 255);\n  display: flex;\n  background-color: #0e0d0d;\n  padding: 2px;\n  height: 25px;\n  line-height: 18px;\n  /* Directly using the height value */\n  font-size: 16px;\n  /* Assuming a direct translation from height - 2px */\n  border: 2px solid;\n}\n\n.leftDiv {\n  position: absolute;\n  top: 31px;\n  left: 0px;\n  max-width: 350px;\n  max-height: calc(100% - 62px);\n  min-height: 50px;\n  overflow: auto;\n  padding: 2px;\n  box-sizing: border-box;\n  border: 1px solid yellow;\n  opacity: 0.8;\n  z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
