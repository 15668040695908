//import { all } from "micromatch";
import { CAD } from "../index.js";
import { distance } from "./mathHelpersMod.js";




// Function to find and sort connected edges
function findAndSortConnectedEdges(startEdge, edges, visited) {
    let sortedEdges = [startEdge];
    visited.add(startEdge.id);  // Use edge.id to mark as visited
    let lastPoint = startEdge.end;

    while (true) {
        let found = false;
        for (let edge of edges) {
            if (!visited.has(edge.id)) {  // Use edge.id to check if visited
                if (edge.start === lastPoint || edge.end === lastPoint) {
                    visited.add(edge.id);  // Use edge.id to mark as visited
                    if (edge.end === lastPoint) {
                        // Reverse the edge to maintain the order
                        [edge.start, edge.end] = [edge.end, edge.start];
                    }
                    sortedEdges.push(edge);
                    lastPoint = edge.end;
                    found = true;
                    break;
                }
            }
        }
        // Check for closed loops
        if (!found) {
            if (sortedEdges[0].start === lastPoint || sortedEdges[0].end === lastPoint) {
                if (sortedEdges[0].end === lastPoint) {
                    // Reverse the edge to maintain the order
                    [sortedEdges[0].start, sortedEdges[0].end] = [sortedEdges[0].end, sortedEdges[0].start];
                }
                // Close the loop by adding the starting edge
                sortedEdges.push(sortedEdges[0]);
                break;
            } else {
                break;
            }
        }
    }

    return sortedEdges;
}


let scriptBuffer = [];

export async function export_OCCT_draw(data ,sketchId="sketch1") {
    //let drawIframe = document.getElementById("drawIframe");
    scriptBuffer = [];
    let listOfWires = [];
    let allEdges = [];

    await setupDrawEnvironment();



    // Generate points and vertices
    let vdisplayPoints = "";
    for (const point of data.points) {
        const { id, x, y, fixed } = point;
        await runDrawCommand(`point ${sketchId}_p${id} ${x} ${y}`);
        await runDrawCommand(`vertex ${sketchId}_p${id} ${sketchId}_p${id}`);

        vdisplayPoints += ` ${sketchId}_p${id}`;
    }
    //console.log(vdisplayPoints);
    await runDrawCommand(`vdisplay ${vdisplayPoints}`);

    // Generate geometries and edges
    for (const geometry of data.geometries) {
        //console.log("Making this pice of geometry now", geometry);
        const { id, type, points, moving } = geometry;

        const pointsReal = points.map(id => data.points.find(p => p.id === id));
        listOfWires.push(`${sketchId}_e${id}`);
        let constructionGeometry = true;
        if (geometry.construction && geometry.construction !== false) constructionGeometry = false;

        if (type === "line") {
            const [start, end] = points;
            if (constructionGeometry) allEdges.push({ id, start, end, points: [start, end], wireID: "" });
            await runDrawCommand(`edge ${sketchId}_e${id} ${sketchId}_p${start} ${sketchId}_p${end}`);
        }

        if (type === "arc") {
            const [center, start, end] = pointsReal;
            if (constructionGeometry) allEdges.push({ id, start: start.id, end: end.id, points: [start.id, end.id], wireID: "" });
            const radius = distance(center, start);

            // Calculate start and end angles in radians
            const startAngle = Math.atan2(start.y - center.y, start.x - center.x);
            const endAngle = Math.atan2(end.y - center.y, end.x - center.x);

            // Generate TCL commands for the arc
            await runDrawCommand(`circle ${sketchId}_e${id} ${center.x} ${center.y} ${radius}`);
            await runDrawCommand(`trim ${sketchId}_e${id} ${sketchId}_e${id} ${startAngle} ${endAngle}`);
            await runDrawCommand(`mkedge ${sketchId}_e${id} ${sketchId}_e${id}`);
        }
        if (type === "circle") {
            const [center, start] = pointsReal;

            const end = start; // Use center as both start and end
            if (constructionGeometry) allEdges.push({ id, start: start.id, end: end.id, points: [start.id, end.id], wireID: "" });
            const radius = distance(center, start);
            await runDrawCommand(`circle ${sketchId}_e${id} ${center.x} ${center.y}  ${radius}`);
            await runDrawCommand(`mkedge ${sketchId}_e${id} ${sketchId}_e${id}`);
        }
        //console.log("Just make this edge", `${sketchId}_e${id}`)

        //await runDrawCommand(`vdisplay e${id}`);
    }

    let wireCounter = 1;
    let wireIDs = [];
    for (const edge of allEdges) {
        //console.log(edge);
        // test if the edge.start or edge.end is present in the points array of any of the other edges and 
        // if so test test if either edge has a wirID and if so make the wireIDs the same. 
        // if not make a new wireID and add it to the edge and the other edge
        let found = false;

        for (let otherEdge of allEdges) {
            if (otherEdge.id !== edge.id) {
                if (otherEdge.points.includes(edge.start) || otherEdge.points.includes(edge.end)) {
                    if (edge.wireID === "" && otherEdge.wireID === "") {
                        edge.wireID = `w${wireCounter}`;
                        otherEdge.wireID = `w${wireCounter}`;
                        wireIDs.push(`w${wireCounter}`);
                        wireCounter++;
                        found = true;
                        break;
                    } else if (edge.wireID !== "" && otherEdge.wireID === "") {
                        otherEdge.wireID = edge.wireID;
                        found = true;
                        break;
                    } else if (edge.wireID === "" && otherEdge.wireID !== "") {
                        edge.wireID = otherEdge.wireID;
                        found = true;
                        break;
                    } else if (edge.wireID !== "" && otherEdge.wireID !== "") {
                        found = true;
                        break;
                    }
                }
            }
        }
    }


    // check if there are any edges without a wireID and if so make a new wireID and add it to the edge
    for (let edge of allEdges) {
        if (edge.wireID === "") {
            edge.wireID = `w${wireCounter}`;
            wireIDs.push(`w${wireCounter}`);
            wireCounter++;

        }
    }

    console.log(wireIDs);
    // Generate wires
    console.log(allEdges);
    for (const wireID of wireIDs) {
        //filter the edges to only include the edges with the wireID and then make an array that contains just the ids of edges
        let edges = await allEdges.filter(edge => edge.wireID === wireID);
        console.log(edges);
        edges = await edges.map(edge => `${sketchId}_e${edge.id}`);


        let edgesString = await edges.map(edge => `${edge}`).join(" ");
        console.log(edgesString);
        await runDrawCommand(`wire ${sketchId}_${wireID} -unsorted ${edgesString}`);
        await runDrawCommand(`fixshape ${sketchId}_${wireID} ${sketchId}_${wireID}`);
    };


    //console.log("Here are my edges ", listOfWires.join(" "), listOfWires);

    await runDrawCommand(`mkplane ${sketchId}_F_${wireIDs[0]} ${sketchId}_${wireIDs[0]}`);
    await runDrawCommand(`fixshape ${sketchId}_F_${wireIDs[0]} ${sketchId}_F_${wireIDs[0]}`);

    // add additional edges to the plane


    for (const wireID of wireIDs) {
        await runDrawCommand(`add ${sketchId}_${wireID} ${sketchId}_F_${wireIDs[0]} `);
    };



    await runDrawCommand(`fixshape ${sketchId}_F_${wireIDs[0]} ${sketchId}_F_${wireIDs[0]}`);

    //await runDrawCommand(`vdisplay w1`);
    await runDrawCommand(`vdisplay ${sketchId}_F_${wireIDs[0]} -dispMode 1`);







    //await runDrawCommand('vdisplayall');
    //await runDrawCommand('vfit');
    let tclScript = scriptBuffer.join("\n");
    //CAD.textArea.value = tclScript;
    return tclScript;
}


async function copyToClipboard(text) {
    try {
        await navigator.clipboard.writeText(text);
        console.log('Text successfully copied to clipboard');
    } catch (err) {
        console.error('Failed to copy text: ', err);
    }
}



let generatedFaces = [];

function doExtrude(face, extrusionVec, length) {
    generatedFaces.push(`P_${face}`);
    return `
    # Capture the output of the normals command to get the normal vector of face F_3
    set normalOutput [normals ${face} -print]
    puts $normalOutput
    
    # Use regular expression to parse the output and get the normal vector components (nx, ny, nz)
    regexp {\(([^,]+), ([^,]+), ([^\)]+)\)} $normalOutput -> _ nx ny nz
    
    # Remove parentheses and trim spaces from the captured values
    set nx [string trim [string map {"(" "" ")" ""} $nx]]
    set ny [string trim [string map {"(" "" ")" ""} $ny]]
    set nz [string trim [string map {"(" "" ")" ""} $nz]]
    
    puts $nx
    puts $ny
    puts $nz
    
    # Set the desired length for the extrusion
    set length ${length}
    
    # Scale the normal vector by the desired length
    set nx [expr {$nx * $length}]
    set ny [expr {$ny * $length}]
    set nz [expr {$nz * $length}]
    
    # Perform the extrusion to create the prism from face F_3 along the scaled normal vector
    prism P_${face} ${face} $nx $ny $nz
    # fixshape P_${face} P_${face}
    

# Display the resulting prism in the viewer
vdisplay P_${face} -dispMode 1
#vfit
`
}



export async function setupDrawEnvironment() {
    //await runDrawCommand("pload all");
    //await runDrawCommand("vinit");
    //await runDrawCommand("vclear");
    // await runDrawCommand("vfit");
    // await runDrawCommand("vviewcube vc2 -transparency 0.5");
    // await runDrawCommand("vdisplay vc2 -trihedron bottomright 100 150");
}



export function runDrawCommand(command) {
    //drawIframe.contentWindow.postMessage(command, "*");
    scriptBuffer.push(command);
}
